import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import PalceHolder21_9 from "../../images/blank-21x9.png"


const HeroSecrtion = styled.section`
  height: calc(100vh - 170px);
  position:relative;
  @media(max-width:1200px){
    height: calc(100vh - 350px);
  }
  @media(max-width:767px){
    height:400px;
  }
  figure{
    overflow:hidden;
    figcaption{
      position:absolute;
      left:0;
      right:0;
      top:70px;
      text-align:center;
      z-index:2;
      color:white;
      @media(max-width:767px){
        top:60px;
      }
      .title{
        text-shadow: 0px 4px 0px #100B3BCC;
        text-transform: uppercase;
        @media(max-width:1199px){
          text-shadow:none;
          font-size:42px;
        }
        @media(max-width:767px){
          text-shadow:none;
          font-size:28px;
        }

        .subtitle{
         
          @media(max-width:767px){
            font-size:18px;
          }
        }
      }
      
    }
  } 
`
const HeroBanner = (props) => {
const [windowLoaded, setwindowLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setwindowLoaded(true)
    },50)
    console.log('run');
  },[props])
  const { BannerTitle, BannerSubtitle, BannerImage, BannerImageMobile } = props;
  return (
    <HeroSecrtion>
      <figure>
        <figcaption>
          <h5 className="subtitle" data-aos="fade-up">{BannerSubtitle}</h5>
          <h1 className="title" data-aos="fade-up">{BannerTitle}</h1>
        </figcaption>
        <img src={PalceHolder21_9} alt="..." className="img-full" />
        {!windowLoaded && <div style={{ position: 'absolute', background: 'gray', left: 0, right: 0, display:'flex', top:'0', bottom:'0', alignItems:'center', justifyContent:'center', color:'#fff'}}>Loading...</div>}
        {windowLoaded && <img src={BannerImage} alt="banner" className="main-img d-none d-md-block" />}
        <img src={BannerImageMobile} alt="banner" className="main-img d-block d-md-none" />
      </figure>
    </HeroSecrtion>
  )
}
export default HeroBanner;




import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Slider from "react-slick"
import {Link} from "gatsby"
import { Section } from '../Section'
import PatternImg from "../../images/line-pattern.png"
import { ArrowLeftIcon, ArrowrRightIcon, ArrowRight } from '../Icons'
import Ripples from 'react-ripples'
import Image from "../image"

function SampleNextArrow(props) {
  const { className, style } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
    />
  );
}
class KnowMoreAbout extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.Slider.slickNext();
  }
  previous() {
    this.Slider.slickPrev();
  }

  render() {
    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 2,
      centerPadding: "40px",
      slidesToScroll: 1,
      NextArrow: <SampleNextArrow />,
      PrevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            centerPadding: "0px",
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerPadding: "0px",
            dots: false,
          }
        }
      ]
    };
    return (
      <Section bgBefore={`url(${PatternImg})`} pt='80px' pb='80px' className='section know-more-about line-pattern'  data-aos="fade-up">
        <Container fluid>
          <Row>
            <Col lg={3}>
              <div className='section-heading'>
                <span className='section-subtitle'>Let Us Help You To</span>
                <h2 className='section-title'>Ready to Learn More?</h2>
              </div>
              <div className="section-content mt-lg-5">
              
                <div className='text-left'>
                  <Ripples className='border-radius-27'>
                    <Button variant="primary icon-left arrow-prev" onClick={this.previous}><ArrowLeftIcon stroke='#fff' width='22px' height='14px' /> PREV</Button></Ripples>
                </div>
                <div className='text-left mt-3'>
                  <Ripples className='border-radius-27'><Button variant="secondary icon-right arrow-next" onClick={this.next}>NEXT <ArrowrRightIcon stroke='#fff' Arro width='22px' height='14px' /></Button>
                  </Ripples>
                </div>
              </div>
            </Col>
            <Col lg={9} className='mr-0'>
              <div className='aboutMoreSlider'>
                <Slider {...settings} ref={c => (this.Slider = c)}>
                  <div className='slider-item'>
                    <Card>
                      <Image name="roofstyles.jpg" alt="roofstyles" />                     
                      <Card.Body>
                        <Card.Title>Roof Styles</Card.Title>
                        <Card.Text>Not sure what you need in a metal building? Or maybe you need some help choosing the right building style and dimensions that will best suit your needs? Our building guide section can help you through the process!</Card.Text>
                        <Link to='/roof-styles' className='text-secondary btn btn-link icon-right pl-0'>Explore <ArrowRight stroke='#cb0000' ArrowDir='right' width="16px" height="16px" /></Link>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='slider-item'>
                    <Card>
                      <Image name="sitepreparation.jpg" alt="sitepreparation" />
                      <Card.Body>
                        <Card.Title>Site Preparation </Card.Title>
                        <Card.Text>When it comes to putting up your custom building, we’ll do all the heavy lifting. But there are just a few things you’ll need to do on your end to ensure that your site is ready for the building install. Check here for some helpful tips.</Card.Text>
                        <Link to='/site-preparation' className='text-secondary btn btn-link icon-right pl-0'>Explore <ArrowRight stroke='#cb0000' ArrowDir='right' width="16px" height="16px" /></Link>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='slider-item'>
                    <Card>
                      <Image name="faq.jpg" alt="faq" />
                      <Card.Body>
                        <Card.Title>FAQs</Card.Title>
                        <Card.Text>We understand that you may still have some questions. Well, we’ve got answers! In this section you’ll find answers to some of the most frequently-asked questions we receive from new customers.</Card.Text>
                        <Link to='/faq' className='text-secondary btn btn-link icon-right pl-0'>Explore <ArrowRight stroke='#cb0000' ArrowDir='right' width="16px" height="16px" /></Link>
                      </Card.Body>
                    </Card>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    )
  }
}

export default KnowMoreAbout;
import React from 'react';
import styled from "styled-components"
import { Container, Row, Col } from 'react-bootstrap'
import { Section } from '../Section'
import RequestForm from '../RequestForm'
import bgImg from '../../images/call-to-action-bg.png';
import PatternImg from "../../images/line-pattern.png"
import DesignYourBuilding from '../../images/design-your-building.svg'
import DesignYourBuildingBg from '../../images/rectangle.svg'
import { Link } from "gatsby"
import Ripples from 'react-ripples'

const FormWrapper = styled.div`   
  padding:70px 80px 60px 0px;
  position:relative;
  @media(max-width:991px){
    padding:0px 0 0px 0; 
  }
`

const RightWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  padding:40px 40px 40px 80px;
  flex-direction:column;
  height: 100%;
  positin:relative;
  z-index:5;
  @media(max-width:991px){
    padding:40px 15px 40px 15px; 
  } 
 `

const TopWrapper = styled.div`
  background:${props => props.bg};
  height:80px;
  position:relative;
  @media(max-width:1199px){
    display:none;
  }
  &:before{
    content:'';
    display:block;
    width: 50%;
    position:absolute;
    left:50%;
    background:${props => props.bgAfter};
    top:0;
    bottom:0;
    z-index:1;
    clip-path: polygon(0% 101%,0% 100%,100% 100%,100% 0%,8% 0);
  }
  &:after{
    content:'';
    display:block;
    width: 50%;
    position:absolute;
    left:50%;
    background:#565969;
    top:0;
    bottom:0;
    clip-path: polygon(0% 101%,0% 100%,100% 100%,100% 0%, 8% 0);  
  }
`
 
const RequestToCall = ({props,location}) => {
  const formId = "Request a Call";
  return (
    <>
    <TopWrapper bg={`url(${PatternImg})`} bgAfter={`url(${DesignYourBuildingBg})`} ></TopWrapper>
    <Section className='section request-to-call' pt='60px' pb='50px' mb='4px' bgBefore={`url(${bgImg})`} bgAfter={`url(${DesignYourBuildingBg})`}>
      <Container>
       <Row noGutters>
            <Col lg={6} md={6} className='request-to-call-cnt'  data-aos="fade-up">
              <FormWrapper>
                <div className='section-heading'>
                <h2 className='section-title'>Request a Call</h2>
                  <span className='section-subtitle'>Ready to Get Started? Give Us a Call!</span>
                </div>
                <RequestForm location = { location } formId = { formId } />
              </FormWrapper>
            </Col>
          <Col lg={6} md={6} className='design-your-building-cnt'  data-aos="fade-up"  data--aos-delay='100'>
              <RightWrapper>
                <div className='section-heading'>
                  <h2 className='section-title'>Design Your Building</h2>
                  <span className='section-subtitle'>Use Our 3D Design Tool to Design Your OWN Building!</span>
                </div>
                <div className='section-content'>
                  <img className='img-fluid' src={DesignYourBuilding} alt="Desing Your Building" />
                </div>
                <div className='section-footer'>
                <div className='d-block mx-auto text-center'>
                <Ripples className='border-radius-27'><Link className='btn btn-primary btn-desing-now' to="https://carportrus.sensei3d.com/" target="_blank" rel="noopener noreferrer" >3D Designer</Link></Ripples>
                    </div>
                </div>
              </RightWrapper>
            </Col>
          </Row>
      </Container>
    </Section>
    </>
  )
}
export default RequestToCall; 
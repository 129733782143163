import React from "react"
import { Scrollbars } from "react-custom-scrollbars"
import { Section, Div } from "../Section"
import Mapbox from "../Mapbox"
import { Container, Row, Col, ListGroup } from "react-bootstrap"
import { ArrowIcon } from "../Icons"
import PatternImg from "../../images/line-pattern.png"

const OurDealers = props => {
  return (
    <Section className="section our-dealers" bgBefore={`url(${PatternImg})`}>
      <Container fluid className="p-0">
        <Div
          bgBefore={`url(${PatternImg})`}
          Position="relative"
          className="our-dealers-inner"
        ></Div>
        <Row noGutters style={{ position: "relative" }}>
          <Col lg>
            <Mapbox />
          </Col>
          <Col lg={4} xl={3} className="serviceAreaLeft">
            <div className="service-area">
              <div className="section-heading">
                <h3 className="section-title">HAPPY TO SERVE IN THE CONTINENTAL UNITED STATES</h3>
                <span className="section-subtitle">If you need a metal building or steel structure in your preferred location, Carports R Us has got you covered!</span>
              </div>
              <div className="section-content">
                <Scrollbars
                  autoHeight
                  autoHeightMin={0}
                  autoHeightMax={500}
                  thumbMinSize={30}
                  universal={true}
                >
                  <ListGroup>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      North Carolina
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                        <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                        Virginia
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      West Virginia
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Pennsylvania
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Connecticut
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Massachusetts
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      New York
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Ohio
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Kentucky
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Tennessee
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      South Carolina
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Georgia
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Texas
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Arkansas
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Oklahoma
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      New Mexico
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Arizona
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      California
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Florida
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Missouri
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Illinois
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Iowa
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Wisconsin
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Minnesota
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                      <ArrowIcon stroke="#fff" ArrowDir="left" width="18px" height="18px" />
                      Indiana
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Scrollbars>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default OurDealers
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TabsContent from '../components/TabsContent'
import MoreInformation from '../components/MoreInformation'
import WhyChoose from '../components/WhyChoose'
import OurDealers from '../components/OurDealers'
import KnowMoreAbout from '../components/KnowMoreAbout'
import RequestToCall from '../components/RequestToCall'
const IndexPage = ({ location }) => (
  <Layout location = { location } >
    <SEO title="Home"/>
    <TabsContent />
    <MoreInformation />
    <WhyChoose />
    <OurDealers />
    <KnowMoreAbout />
    <RequestToCall location = { location } />
  </Layout>
)

export default IndexPage
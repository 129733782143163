import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Section } from '../Section'
import { FairPolicyIcon, CustomerIcon, QualityIcon, WarantyIcon, LocationIcon, DiscountIcon, ArrowRight } from '../Icons'
import PatternImg from "../../images/line-pattern.png"
import AfterBg from '../../images/aboutus-after-bg.png'
import { Link } from 'gatsby'

const WhyChoose = (props) => {
  return (
    <Section className='section why-choose line-pattern' pt='100px' pb='40px' bgBefore={`url(${PatternImg})`}  bgAfter={`url(${AfterBg})`}>
      <Container>
        <Row>
          <Col lg={4} xl={3} data-aos='fade-up'>
            <div className='section-heading'>
              <span className='h5 section-subtitle'>About Us</span>
              <h2 className='section-title'>Why Choose Carports R Us?</h2>
            </div>
            <div className='section-content'>
              <p>At Carports R Us, our vision is really pretty simple. We’re here to build our business with customers just like you. We work to ensure that you’re thrilled with our product, and we’re committed to help you receive the metal structure you want with the quickest delivery and installation as possible!</p>
            </div>
            <div className='section-footer'>
            <Link to="/about" className='btn btn-link text-secondary icon-right pl-0'>Know More <ArrowRight stroke='#cb0000' width="14px" height="14px" /></Link>
            </div>
          </Col> 
           
          <Col lg={8} xl={9} data-aos='fade-up' data-aos-delay='200'>
          <Row>
              <Col md={6} data-aos='fade-up' data-aos-delay='300'>
                <div className='item'>
                <div className='icon-box'>
                    <FairPolicyIcon width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                    </div>
                  <div className='item-content'>
                    <h5 className='item-heading'>Fair Price Policy</h5>
                    <p>We’re here to get you a metal building to meet your needs and your budget.</p>
                    </div>
                </div>
              </Col>
              <Col md={6} data-aos='fade-up' data-aos-delay='350'>
                <div className='item'>
                  <div className='icon-box'>
                    <CustomerIcon width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                    </div>
                  <div className='item-content'>
                  <h5 className='item-heading'>Customer Centric </h5>
                    <p>You, our customers, are the reason why we’re here! We exist to serve your needs.</p>
                    </div>
                </div>
              </Col>
              <Col md={6} data-aos='fade-up' data-aos-delay='400'>
                <div className='item'>
                <div className='icon-box'>
                    <QualityIcon width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                    </div>
                  <div className='item-content'>
                  <h5 className='item-heading'>Quality Materials </h5>
                    <p>We only use the best materials to produce metal buildings in 12-gauge and 14-gauge options.</p>
                    </div>
                </div>
              </Col>
              <Col md={6} data-aos='fade-up' data-aos-delay='450'>
                <div className='item'>
                <div className='icon-box'>
                    <WarantyIcon width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                    </div>
                  <div className='item-content'>
                  <h5 className='item-heading'>20-Year Warranty</h5>
                    <p>We provide a 20-year warranty for the frame and roof, and a 30-day workmanship warranty.</p>
                    </div>
                </div>
              </Col>
              <Col md={6} data-aos='fade-up' data-aos-delay='500'>
                <div className='item'>
                <div className='icon-box'>
                    <LocationIcon width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                </div>
                  <div className='item-content'>
                  <h5 className='item-heading'>Serving NC & Beyond</h5>
                    <p>Of course we serve North Carolina, but we also serve 4 other surrounding states as well.</p>
                    </div>
                </div>
              </Col>
              <Col md={6} data-aos='fade-up' data-aos-delay='550'>
                <div className='item'>
                <div className='icon-box'>
                    <DiscountIcon width='98px' height='98px' fill='#cb0000' stroke='#cb0000' />
                    </div>
                  <div className='item-content'>
                  <h5 className='item-heading'>Quick Installation</h5>
                    <p>We get you your building fast! We can usually install within 3-4 weeks.</p>
                    </div>
                </div>
              </Col>
              <Col className='d-block d-lg-none text-center'>
                <Link to="/about" className='btn btn-link text-secondary icon-right pl-0'>Know More <ArrowRight stroke='#cb0000' width="14px" height="14px" /></Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  ) 

}

export default WhyChoose; 
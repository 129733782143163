import React from "react"
import { Link } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"
import { ArrowRight } from "../Icons"

import Image from "../image"

const GarageTabContent = props => {
  const { tabsSubtitle, tabTitle, tabDescription, tabLink, tabImage } = props

  return (
    <>
      <Row className="tab-card" data-aos="fade-up">
        <Col lg={6}>
          <div className="card-inner">
            <Card>
              <Card.Body>
                <span className="card-subtitle">{tabsSubtitle}</span>
                <Card.Title>{tabTitle}</Card.Title>
                <Card.Text>{tabDescription}</Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link
                  to={tabLink}
                  className="text-secondary btn btn-link icon-right"
                >
                  Explore{" "}
                  <span>
                    <ArrowRight
                      stroke="#cb0000"
                      ArrowDir="right"
                      width="14px"
                      height="14px"
                    />
                  </span>
                </Link>
              </Card.Footer>
            </Card>
          </div>
        </Col>
        <Col lg={7}>
          <Image name={tabImage} alt={tabImage} />
        </Col>
      </Row>
    </>
  )
}

export default GarageTabContent

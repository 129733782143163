import React from "react"
import styled from "styled-components"
import Image from "../image"

const BackgroundImage = styled.div`
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left:0;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`
const Mapbox = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items:center;
	min-height: 400px;
    position: relative;
    height: 100%;
    width: 100%;
`
const Map = () => {
	return(	
		<Mapbox>
			<BackgroundImage>
				<Image name="mapbox.jpg" alt="mapbox" />
			</BackgroundImage>
		</Mapbox>
	)
}

export default Map
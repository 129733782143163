import React from "react"
import { Tabs, Tab, Container } from "react-bootstrap"
import { BarnIcon, CarportIcon, GarageIcon, OtherBuildingIcon } from "../Icons"
import { Section } from "../Section"
import HeroSection from "../HeroBanner"
import GarageBannerImg from "../../images/hero_images/hero-garages.jpg"
import CarportBannerImg from "../../images/hero_images/hero-carport.jpg"
import BarnBannerImg from "../../images/hero_images/hero-barn.jpg"
import MetalBuildingBannerImg from "../../images/hero_images/hero-metal-building.jpg"
import BannerImageMobile from "../../images/hero_images/mobile-garages.jpg"
import CaportBannerImgMobile from "../../images/hero_images/mobile-carport.jpg"
import BarnBannerImgMobile from "../../images/hero_images/mobile-barn.jpg"
import MetalBuildingBannerImgMobile from "../../images/hero_images/mobile-metal-building.jpg"

import PatternImg from "../../images/line-pattern.png"
import TabData from "./Tabdatalist"

const TabContent = props => {
  return (
    <Section className="tabs-section" bg={`url(${PatternImg})`} pb="50px">
      <Tabs defaultActiveKey="garage" id="uncontrolled-tab-example">
        <Tab
          eventKey="garage"
          title={
            <span>
              <GarageIcon stroke="#fff" />
              <span>Garages</span>{" "}
            </span>
          }
        >
          <HeroSection
            BannerTitle="Built Stronger to last Longer"
            BannerSubtitle="Steel Garages"
            BannerImageMobile={`${BannerImageMobile}`}
            BannerImage={`${GarageBannerImg}`}
          />
          <Container>
            <TabData
              doorType="tripple"
              tabsSubtitle="Garages"
              tabTitle="Single Car Garages"
              tabDescription="Also known as one-car or single garages, these are perfect for keeping any single passenger vehicle safe and protected. You choose the dimensions and options you want for your building, and we’ll take care of the rest!"
              tabImage="single_car_garage_03.jpg"
              tabLink="/metal-garages/single-car-garages"
            />
            <TabData
              doorType="double"
              tabsSubtitle="Garages"
              tabTitle="Double Car Garages"
              tabDescription="Also known as a two-car or double garage, these buildings are some of the most popular style we sell. Ideal for two-car families, or for those who’d like to protect one car and still have space for extra storage."
              tabImage="double_car_garage_02.jpg"
              tabLink="/metal-garages/double-car-garages"
            />
            <TabData
              doorType="single"
              tabsSubtitle="Garages"
              tabTitle="Triple Wide Garages"
              tabDescription="Also known as three-car or triple garages, these steel structures make great multipurpose buildings. You can use it to house three vehicles, or you can use it to provide a combination of garage, workshop, and storage space."
              tabImage="triple_car_garage_01.jpg"
              tabLink="/metal-garages/triple-wide-garages"
            />
            <TabData
              tabsSubtitle="Garages"
              tabTitle="RV Garages"
              tabDescription="Sometimes a regular-sized garage just won’t do. Need something taller to accommodate your RV, motorhome, camper, boat, farm tractor, or other high-profile vehicle? Not a problem, we can build to your specifications!"
              tabImage="RV_garage_03.jpg"
              tabLink="/metal-garages/rv-garages"
            />
          </Container>
        </Tab>
        <Tab
          eventKey="carport"
          title={
            <span>
              {" "}
              <CarportIcon stroke="#fff" />
              <span>Carports</span>{" "}
            </span>
          }
        >
          <HeroSection
            BannerTitle="Built Stronger to last Longer"
            BannerSubtitle="Steel Carports"
            BannerImageMobile={`${CaportBannerImgMobile}`}
            BannerImage={`${CarportBannerImg}`}
          />
          <Container>
            <TabData
              doorType="tripple"
              tabsSubtitle="Carports"
              tabTitle="Single Car Carports "
              tabDescription="These are the smallest sizes of carports we offer, and typically start out with dimensions of around 12’ in width and 20’ in length. Of course, we can also customize those dimensions for you, depending on what you need!"
              tabImage="sing_car_carports_02.jpg"
              tabLink="/metal-carports/single-car-carports"
              
            />
            <TabData
              doorType="double"
              tabsSubtitle="Carports"
              tabTitle="Double Car Carports"
              tabDescription="These structures are designed to be able to cover two passenger vehicles comfortably, and typically range between 18 feet to 24 feet wide. We recommend going as wide as you can, to give you more room to move between vehicles."
              tabImage="Double_Car_Carports_01.jpg"
              tabLink="/metal-carports/double-car-carports"
            />
            <TabData
              doorType="single"
              tabsSubtitle="Carports"
              tabTitle="Triple Wide Carports"
              tabDescription="One of the nice things about a triple wide carport is the fact that it can be used for more than just covering vehicles. It can also double as a picnic shelter, outdoor workshop, or whatever you need!"
              tabImage="triple_Car_Carports_01.jpg"
              tabLink="/metal-carports/triple-wide-carports"
            />
            <TabData
              tabsSubtitle="Carports"
              tabTitle="RV Carports"
              tabDescription="It’s great having your own RV, but it’s also important to have a protected space to park it when you’re not out on the road. We can provide RV covers to accommodate recreational vehicles of any size."
              tabImage="RV_covers_02.jpg"
              tabLink="/metal-carports/rv-carports"
            />
          </Container>
        </Tab>
        <Tab
          eventKey="barn"
          title={
            <span>
              {" "}
              <BarnIcon stroke="#fff" />
              <span>Barns</span>{" "}
            </span>
          }
        >
          <HeroSection
            BannerTitle="Built Stronger to last Longer"
            BannerSubtitle="Steel Barns"
            BannerImageMobile={`${BarnBannerImgMobile}`}
            BannerImage={`${BarnBannerImg}`}
          />
          <Container>
            <TabData
              doorType="tripple"
              tabsSubtitle="Barns"
              tabTitle="Regular Roof Barns"
              tabDescription="Our regular roof barns offer a classic look that comes at an attractive price. They feature a rounded appearance, and come with horizontal roof paneling. They’re a very popular choice for all sorts of smaller applications."
              tabImage="Regular_barn_01.jpg"
              tabLink="/barns/regular-roof-barns"
            />
            <TabData
              doorType="double"
              tabsSubtitle="Barns"
              tabTitle="Raised Center Barns"
              tabDescription="Our raised center barns feature a raised ceiling above a full-length center section, which is flanked by shorter full-length sections on either side. These provide a classic barn look, with a lot of practical functionality."
              tabImage="Raised_center_barn_03.jpg"
              tabLink="/barns/raised-center-barns"
            />
            <TabData
              doorType="single"
              tabsSubtitle="Barns"
              tabTitle="Straight Roof Barns"
              tabDescription="Our straight roof barns look much the way they sound – they include a continuous roof slope and a higher central roof peak than some of our other barn styles. These barns are great for shedding rainwater and debris."
              tabImage="straight_roof_barn_03.jpg"
              tabLink="/barns/straight-roof-barns"
            />
          </Container>
        </Tab>
        <Tab
          eventKey="othersbuildings"
          title={
            <span>
              {" "}
              <OtherBuildingIcon stroke="#fff" /> <span>Metal Buildings</span>{" "}
            </span>
          }
        >
          <HeroSection
            BannerTitle="Built Stronger to last Longer"
            BannerSubtitle="Metal Buildings"
            BannerImageMobile={`${MetalBuildingBannerImgMobile}`}
            BannerImage={`${MetalBuildingBannerImg}`}
          />
          <Container>
            <TabData
              doorType="tripple"
              tabsSubtitle="Metal Buildings"
              tabTitle="Clear Span Buildings"
              tabDescription="Thanks to the benefits of framing with steel, we can provide you with a clear span building that has 100% usable interior space. These buildings don’t require any interior columns or supports!"
              tabImage="Clear Span Buildings_01.jpg"
              tabLink="/metal-buildings/clear-span-buildings"
            />
            <TabData
              doorType="double"
              tabsSubtitle="Metal Buildings"
              tabTitle="Workshop Buildings"
              tabDescription="How’d you like to have some protected space for doing more than just parking your vehicles? If you’re looking for a workshop, or a building that you can customize to include some dedicated workshop space, we can do it!"
              tabImage="Workshop Buildings_03.jpg"
              tabLink="/metal-buildings/workshop-buildings"
            />
            <TabData
              doorType="single"
              tabsSubtitle="Metal Buildings"
              tabTitle="Storage Buildings"
              tabDescription="Maybe you don’t really need a carport or garage; perhaps you just need an outdoor facility for storing stuff. Our metal buildings and steel structures are perfect for nearly any storage needs you may have!"
              tabImage="Storage Buildings_02.jpg"
              tabLink="/metal-buildings/storage-buildings"
            />
            <TabData
              tabsSubtitle="Metal Buildings"
              tabTitle="Insulated Buildings"
              tabDescription="Perhaps you want to do more than just shelter your items from sun and rain. Maybe you need to protect them from temperature extremes, as well. We can add insulated sheeting to any of our enclosed buildings."
              tabImage="Insulated Buildings_01.jpg"
              tabLink="/metal-buildings/insulated-buildings"
            />
          </Container>
        </Tab>
      </Tabs>
    </Section>
  )
}

export default TabContent
